import { StylesTimeKeeping } from './styled';
import useTimeKeeping, { Props } from './hook';
import moment from 'moment';
import { Dialog, DialogContent, Modal } from '@mui/material';
import { DatePicker, DatePickerProps } from 'antd';
import { styled } from '@mui/material/styles';
import locale from 'antd/es/date-picker/locale/ja_JP';
import 'moment/locale/ja';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd';
interface DataType {
    submit_date?: string;
    check_in_time?: string;
    check_out_time?: string;
    is_manual_checkin?: boolean;
    is_manual_checkout?: boolean;
    isEdit?: boolean;
  }
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const TimeKeepingLayout = ({
    handleCheckin,
    handleCheckout,
    workingTime,
    loading,
    isShowEditForm,
    setIsShowEditForm,
    onChangeStartTime,
    onChangeEndTime,
    selectedItem,
    setSelectedItem,
    handleUpdateWorkingTimeItem,
    workingTimeItemsBefore
}: Props) => {
    const columns: ColumnsType<DataType> = [
        {
            title: '日付',
            dataIndex: 'submit_date',
            key: 'submit_date',
            render: (text, record) => <div className="vehicle-name min-w-[80px]">{workingTime?.submit_date ? moment(workingTime?.submit_date).format('YYYY/MM/DD') : ''}</div>,
            width: 200,
        },
        {
            title: '出勤時間',
            dataIndex: 'start_time',
            key: 'start_time',
            render: (text, record) => <div className={`vehicle-name min-w-[90px] ${record?.is_manual_checkin ? 'text-[#f44336]' : ''}`}>{record?.check_in_time
                ? moment(record?.check_in_time).format(
                    'YYYY/MM/DD HH:mm',
                )
                : ''}</div>,
            width: 200,
        },
        {
            title: '退勤時間',
            dataIndex: 'end_time',
            key: 'end_time',
            render: (text, record) => <div className={`vehicle-name min-w-[90px] ${record?.is_manual_checkout ? 'text-[#f44336]' : ''}`}>{record?.check_out_time
                ? moment(record?.check_out_time).format(
                    'YYYY/MM/DD HH:mm',
                )
                : ''}</div>,
            width: 200,
        },
        {
            title: '操作',
            key: 'action',
            width: 180,
            align: 'center',
            render: (_, record) => (
                <div className="flex items-center gap-[4px]">
                  <button
                        className={`editBtn ${!record?.isEdit ? 'opacity-70' : ''}`}
                        onClick={() => {setIsShowEditForm(true), setSelectedItem({...record, worker_id: workingTime?.worker?.id})}}
                       disabled={!record?.isEdit}
                    >
                        編集
                    </button>
                </div>
            ),
        },
    ];
    let checkInAfterToday = selectedItem?.check_in_time && new Date(selectedItem?.check_in_time).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)
    let checkOutAfterToday = selectedItem?.check_out_time && new Date(selectedItem?.check_out_time).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)
    
    let isCheckInPast = selectedItem?.check_in_time && new Date(selectedItem?.check_in_time).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
    let isCheckoutPast = selectedItem?.check_out_time && new Date(selectedItem?.check_out_time).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
    const range = (start, end) => {
        const result: number[] = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };

    const disabledDateTimeIn = () => ({
        disabledHours: () => isCheckInPast ? [] : range(new Date().getHours() + 1, 24),
        disabledMinutes: () => isCheckInPast ? [] : range(new Date().getMinutes() + 1, 60),
    });
    const disabledDateTimeOut = () => ({
        disabledHours: () => isCheckoutPast ? [] : range(new Date().getHours() + 1, 24),
        disabledMinutes: () => isCheckoutPast ? [] : range(new Date().getMinutes() + 1, 60),
    });
    return (
        <StylesTimeKeeping>
            <BootstrapDialog
                className="customBootstrapDialog scheduleFormDialog"
                onClose={() => setIsShowEditForm(false)}
                aria-labelledby="customized-dialog-title"
                open={isShowEditForm}
            >
                <DialogContent className='!min-h-[500px]'>
                    <div className="flex mt-4 items-center gap-x-[6px]">
                        <p className="fieldLabel !mb-0">
                            出勤時間
                        </p>
                        <div className="">
                            <DatePicker
                                id='time-check-in'
                                inputReadOnly
                                value={selectedItem?.check_in_time ? moment(selectedItem?.check_in_time) : null}
                                showTime 
                                onChange={onChangeStartTime}
                                locale={locale}
                                format={'YYYY/MM/DD HH:mm'}
                                disabledDate={(current) => {
                                    return current && current.toDate().setHours(0,0,0,0) > new Date().setHours(0,0,0,0);
                                }} 
                                disabledTime={disabledDateTimeIn}
                                onSelect={() => {
                                    setTimeout(() => {
                                        let timeStartSelect = (document.querySelector("#time-check-in") as HTMLElement)?.getAttribute('value');
                                        setSelectedItem({...selectedItem, check_in_time: timeStartSelect, id_in: selectedItem?.checkin_item_id})
                                    }, 100)
                                }}
                            />
                        </div>
                    </div>
                    {checkInAfterToday && <p className='text-[#f44336] ml-[60px]'>無効な値</p>}
                    <div className="flex mt-4 items-center gap-x-[6px]">
                        <p className="fieldLabel !mb-0">
                            退勤時間
                        </p>
                        <div className="">
                            <DatePicker
                                id="time-check-out"
                                inputReadOnly
                                value={selectedItem?.check_out_time ? moment(selectedItem?.check_out_time) : null}
                                showTime
                                onChange={onChangeEndTime}
                                locale={locale}
                                format={'YYYY/MM/DD HH:mm'}
                                disabledDate={(current) => {
                                    return current && current.valueOf() > Date.now();
                                }}
                                disabledTime={disabledDateTimeOut}
                                onSelect={() => {
                                    setTimeout(() => {
                                        let timeEndSelect = (document.querySelector("#time-check-out") as HTMLElement)?.getAttribute('value');
                                        setSelectedItem({...selectedItem, check_out_time: timeEndSelect, id_out: selectedItem?.checkout_item_id, is_create: selectedItem?.checkout_item_id ? false : true})
                                    }, 100)
                                }}
                            />
                        </div>
                    </div>
                    {checkOutAfterToday && <p className='text-[#f44336] ml-[60px] mb-0'>無効な値</p>}
                    <p className='text-[#f44336] ml-[60px]'>{new Date(selectedItem?.check_in_time) > new Date(selectedItem?.check_out_time) && selectedItem?.check_in_time && selectedItem?.check_out_time ? '退勤時刻は出勤時刻以降より入力してください' : ''}</p>
                    <div className='flex justify-end'>
                    <button
                        className="w-[80px] h-[30px] rounded-[4px] bg-[#215493] text-[#ffffff]"
                        onClick={handleUpdateWorkingTimeItem}
                        disabled={(selectedItem?.check_in_time && selectedItem?.check_out_time && new Date(selectedItem?.check_in_time) > new Date(selectedItem?.check_out_time)) || checkInAfterToday || checkOutAfterToday}
                    >
                        編集
                    </button>
                    </div>
                </DialogContent>
            </BootstrapDialog>

            <p className="time--title">出退勤</p>
            <div className="flex mt-4 mb-[12px] justify-center gap-[12px]">
                {
                    (workingTime?.working_time_items?.length % 2 === 0 || !workingTime?.working_time_items) && (workingTimeItemsBefore?.length % 2 === 0 || !workingTimeItemsBefore) ? <div
                        onClick={() => handleCheckin()}
                        className='timekeeping bg-[#b8f5ed]'
                    >
                        出勤
                    </div> : <div
                        onClick={() => handleCheckout()}
                        className='timekeeping bg-[#ffd8d9]'
                    >
                        退勤
                    </div>
                }
            </div>
            {workingTime?.working_time_items_pair?.length > 0 && (
                <Table
                    rowKey="id"
                    columns={columns}
                    pagination={false}
                    dataSource={workingTime?.working_time_items_pair?.sort((a,b) => b?.checkin_item_id - a?.checkin_item_id)}
                    className='overflow-auto mt-[24px]'
                />
            )}
        </StylesTimeKeeping>
    );
};

const TimeKeeping = (props: any) => {
    return <TimeKeepingLayout {...useTimeKeeping(props)} />;
};

export default TimeKeeping;
